<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="800"
    scrollable
    persistent
  >
    <ProjectUserStoriesBulkForm
      :form-item="formItem"
      :errors="projectUserStoryValidationErrors"
      :disabled="$store.getters.loading['post:api/project-user-stories/bulk-insert']"
      @clear:errors="CLEAR_PROJECT_USER_STORY_VALIDATION_ERRORS"
      @cancel="onCancel"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import ProjectUserStoriesBulkForm from '@/components/forms/ProjectUserStoriesBulkForm';

export default {
  name: 'CreateProjectUserStoryInBulk',

  components: { ProjectUserStoriesBulkForm },

  data() {
    return {
      formItem: {
        project_id: this.$route.params.projectId,
        sprint_id: null,
      },
    };
  },

  computed: {
    ...mapState('projectUserStories', ['projectUserStoryValidationErrors']),
  },

  created() {
    this.SET_PROJECT_USER_STORY_VALIDATION_ERRORS({});
  },

  methods: {
    ...mapActions('projectUserStories', ['storeProjectUserStoriesBulk']),
    ...mapMutations('projectUserStories', [
      'SET_PROJECT_USER_STORY_VALIDATION_ERRORS',
      'CLEAR_PROJECT_USER_STORY_VALIDATION_ERRORS',
    ]),

    onCancel() {
      this.goBack();
    },

    onSave(projectUserStory) {
      this.storeProjectUserStoriesBulk(projectUserStory).then(() => {
        this.goBack();
      });
    },

    goBack() {
      this.$router.push({ name: 'project' });
    },
  },
};
</script>
