<template>
  <v-card>
    <v-card-title>
      {{ $t('projects.controls.mass_insertion') }}
    </v-card-title>

    <v-card-text>
      <v-row dense>
        <v-col v-if="currentUserPermissionInSelectedProject === 'admin'" cols="12">
          <v-select
            v-model="item.sprint_id"
            :items="projectSprints"
            :error-messages="errors.sprint_id"
            :label="$t('projects.labels.assign_to_sprint')"
            item-text="sprint_name"
            item-value="id"
            clearable
            @change="formMixin_clearErrors('sprint_id')"
          />
        </v-col>

        <v-col cols="12">
          <v-textarea
            v-model="item.info"
            :error-messages="errors.info"
            :label="$t('projects.general.task_list')"
            :hint="$t('projects.general.stories_separate_new_line')"
            auto-grow
            @input="formMixin_clearErrors('info')"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer />

      <v-btn text @click="$emit('cancel')">
        {{ $t('general.controls.cancel') }}
      </v-btn>

      <v-btn
        :disabled="disabled"
        :loading="disabled"
        color="primary"
        text
        @click="$emit('save', item)"
      >
        {{ $t('general.controls.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import formMixin from '@/mixins/form-mixin';
import { mapGetters, mapState } from 'vuex';
import clone from 'just-clone';

export default {
  name: 'ProjectUserStoriesBulkForm',

  mixins: [formMixin],

  props: {
    formItem: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      item: {},
    };
  },

  computed: {
    ...mapState('projectSprints', ['projectSprints']),
    ...mapGetters('projects', ['currentUserPermissionInSelectedProject']),
  },

  created() {
    this.item = clone(this.formItem);
  },
};
</script>
